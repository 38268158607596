import { toISODateString } from "@/helpers/string";
import { PayRate } from "@/types/api";

export enum PayRateType {
  Past,
  Current,
  Future,
}

export const getPayRateTypes = (
  payRates: PayRate[],
): (PayRate & { type: PayRateType })[] => {
  const sortedPayRates = [...payRates];
  sortedPayRates.sort((a, b) =>
    b.effective_start.localeCompare(a.effective_start),
  );

  const today = toISODateString(new Date());
  let foundCurrent = false;

  const payRateTypes = sortedPayRates.reduce((acc, { id, effective_start }) => {
    let type = PayRateType.Past;

    if (effective_start > today) {
      type = PayRateType.Future;
    } else {
      type = foundCurrent ? PayRateType.Past : PayRateType.Current;
      foundCurrent = true;
    }

    acc.set(id, type);

    return acc;
  }, new Map<string, PayRateType>());

  return payRates.map((payRate) => ({
    ...payRate,
    type: payRateTypes.get(payRate.id)!,
  }));
};
