import {
  BiologicalSexOptions,
  PayFrequencyOptions,
  PayPeriodMapping,
  PayPeriodOptions,
} from "@/constants";
import { useApiClient } from "@/helpers/hooks";
import {
  UsCurrencyFormatter,
  UsMediumDateFormatter,
  snakeCaseToTitleCase,
  toISODateString,
} from "@/helpers/string";
import { ClaspEmployerConfig } from "@/types";
import { RoleMapping, Roles, WritableMember } from "@/types/api";
import { DeepPartial } from "@/types/utils";
import {
  Box,
  Button,
  Divider,
  Grid,
  Group,
  Modal,
  NumberInput,
  Radio,
  Select,
  Stack,
  Stepper,
  Text,
  TextInput,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconMail } from "@tabler/icons-react";
import { useState } from "react";
import { AddressFieldGroup } from "../Form/AddressFieldGroup";
import { OptionalRadioGroup } from "../Form/OptionalRadioGroup";
import {
  CreateEmployeeDetailsSchema,
  CreateEmployeeType,
  EmployeeRoleSchema,
} from "./form";
import { useCreateEmployeeMutation, useCreatePayRateMutation } from "./hooks";
import classes from "./styles.module.css";

const ReviewItem = ({ label, value }: { label: string; value: string }) => (
  <Box>
    <Text fw={400} size="sm" c="gray.7">
      {label}
    </Text>
    <Text size="sm" fw={600}>
      {value}
    </Text>
  </Box>
);

type Props = {
  opened: boolean;
  onClose: () => void;
};

export const CreateEmployeeModal = ({ opened, onClose }: Props) => {
  const [active, setActive] = useState(0);
  const employeeMutation = useCreateEmployeeMutation();
  const payRateMutation = useCreatePayRateMutation();
  const client = useApiClient();
  const form = useForm<
    DeepPartial<CreateEmployeeType>,
    (CreateEmployeeType: CreateEmployeeType) => CreateEmployeeType
  >({
    initialValues: { address: {} },
    validate: (values) =>
      active === 0
        ? zodResolver(CreateEmployeeDetailsSchema)(values)
        : zodResolver(EmployeeRoleSchema)(values),
  });
  const values = form.getTransformedValues(form.values);

  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current + 1;
    });

  const prevStep = () => {
    active === 0 ? onClose() : setActive((current) => current - 1);
  };

  const onSubmit = form.onSubmit(async (values) => {
    const { tobacco_usage, pay_amount, pay_period, ...parsedValues } = values;

    const hire_date = toISODateString(values.hire_date);

    const { id } = await employeeMutation.mutateAsync({
      client,
      data: {
        ...parsedValues,
        employer: (client.config as ClaspEmployerConfig).employerId,
        dob: toISODateString(values.dob),
        hire_date,
        hours_worked: values.hours_worked.toString(),
        role: values.role,
        tobacco_usage: tobacco_usage,
      } as WritableMember,
    });
    payRateMutation.mutate(
      {
        data: {
          amount: pay_amount,
          member: id,
          period: pay_period,
          effective_start: hire_date,
        },
      },
      {
        onSuccess: () => {
          notifications.show({
            title: "Employee Created",
            message: `Employee ${values.first_name} ${values.last_name} has successfully been created.`,
          });
          form.reset();
          setActive(0);
          onClose();
        },
      },
    );
  });

  return (
    <Modal.Root opened={opened} onClose={onClose} centered size="540">
      <Modal.Overlay />
      <Modal.Content px="md" pt="md" radius="md">
        <Modal.Body p={"md"}>
          <Stack gap={"xs"} mb={32}>
            <Modal.Title classNames={{ title: classes.modalTitle }}>
              Add a Team Member
            </Modal.Title>
            <Text fw={400} c="gray.7">
              Tell us about who you would like to add
            </Text>
          </Stack>
          <form onSubmit={onSubmit}>
            <Stepper
              active={active}
              onStepClick={setActive}
              size="sm"
              allowNextStepsSelect={false}
            >
              <Stepper.Step label="Member Details">
                <Stack>
                  <Grid>
                    <Grid.Col span={6}>
                      <TextInput
                        label="First Name"
                        placeholder="First Name"
                        {...form.getInputProps("first_name")}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Last Name"
                        placeholder="Last Name"
                        {...form.getInputProps("last_name")}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Select
                        label="Sex"
                        placeholder="Sex"
                        data={BiologicalSexOptions}
                        {...form.getInputProps("biological_sex")}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Social Security Number"
                        placeholder="Social Security Number"
                        {...form.getInputProps("ssn")}
                      />
                    </Grid.Col>
                  </Grid>
                  <TextInput
                    label="Email"
                    placeholder="Email"
                    leftSection={<IconMail size={16} />}
                    {...form.getInputProps("email")}
                  />
                  <TextInput
                    label="Phone Number"
                    placeholder="Phone Number"
                    {...form.getInputProps("phone_number")}
                  />
                  <DateInput
                    label="Date of Birth"
                    placeholder="Date of Birth"
                    {...form.getInputProps("dob")}
                  />
                  <AddressFieldGroup form={form} basePath="address" />
                  <OptionalRadioGroup
                    label="Tobacco Usage"
                    {...form.getInputProps("tobacco_usage")}
                  >
                    <Group>
                      <Radio value="true" label="Yes" />
                      <Radio value="false" label="No" />
                    </Group>
                  </OptionalRadioGroup>
                  <Divider my={4} />
                  <Group justify="flex-end">
                    <Button variant="default" onClick={prevStep}>
                      Cancel
                    </Button>
                    <Button onClick={nextStep}>Continue</Button>
                  </Group>
                </Stack>
              </Stepper.Step>
              <Stepper.Step label="Role Details">
                <Stack>
                  <DateInput
                    label="Start Date"
                    placeholder="Start Date"
                    {...form.getInputProps("hire_date")}
                  />
                  <Grid>
                    <Grid.Col span={6}>
                      <Select
                        label="Pay Frequency"
                        data={PayFrequencyOptions}
                        {...form.getInputProps("pay_frequency")}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Select
                        label="Role"
                        placeholder="Role"
                        data={Roles.map((value) => ({
                          label: RoleMapping[value],
                          value,
                        }))}
                        {...form.getInputProps("role")}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <NumberInput
                        label="Hours Worked"
                        placeholder="Hours Worked"
                        hideControls
                        suffix=" hrs"
                        {...form.getInputProps("hours_worked")}
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={6}>
                      <NumberInput
                        label="Pay"
                        placeholder="$"
                        prefix="$"
                        hideControls
                        thousandSeparator=","
                        {...form.getInputProps("pay_amount")}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Select
                        label="Period"
                        data={PayPeriodOptions}
                        {...form.getInputProps("pay_period")}
                      />
                    </Grid.Col>
                  </Grid>

                  <Divider my={4} />
                  <Group justify="flex-end">
                    <Button variant="default" onClick={prevStep}>
                      Back
                    </Button>
                    <Button onClick={nextStep}>Continue</Button>
                  </Group>
                </Stack>
              </Stepper.Step>
              <Stepper.Step label="Review">
                <Stack>
                  <Text size="lg" fw={600}>
                    {values.first_name} {values.last_name}
                  </Text>
                  <ReviewItem label="Email" value={values.email} />
                  <ReviewItem
                    label="Phone Number"
                    value={values.phone_number}
                  />
                  <ReviewItem
                    label="Date of Birth"
                    value={UsMediumDateFormatter.format(values.dob)}
                  />
                  <ReviewItem
                    label="Address"
                    value={`${values.address.line1} ${
                      values.address.line2 ? values.address.line2 : ""
                    }, ${values.address.city}, ${values.address.state} ${
                      values.address.zip_code
                    }`}
                  />
                  <ReviewItem
                    label="Tobacco Usage"
                    value={`${
                      values.tobacco_usage == null
                        ? "-"
                        : values.tobacco_usage
                        ? "Yes"
                        : "No"
                    }`}
                  />
                  <ReviewItem
                    label="Start Date"
                    value={UsMediumDateFormatter.format(values.hire_date)}
                  />
                  <ReviewItem
                    label="Pay Frequency"
                    value={snakeCaseToTitleCase(values.pay_frequency)}
                  />
                  <ReviewItem
                    label="Pay"
                    value={`${UsCurrencyFormatter.format(values.pay_amount)} ${
                      PayPeriodMapping[values.pay_period]
                    }`}
                  />
                  <ReviewItem
                    label="Hours Worked"
                    value={values.hours_worked?.toString()}
                  />
                  <Divider my={4} />
                  <Text size="sm" c="gray.7" fw={400}>
                    After creation, employees will receive an email link to
                    enroll and choose their benefits.
                  </Text>
                  <Group justify="flex-end">
                    <Button variant="default" onClick={prevStep}>
                      Back
                    </Button>
                    <Button type="submit" loading={employeeMutation.isPending}>
                      Create
                    </Button>
                  </Group>
                </Stack>
              </Stepper.Step>
            </Stepper>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
