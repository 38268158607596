import {
  Plan,
  PlanQuote,
  Group as CarrierGroup,
  PlanDetails,
} from "@/types/api";
import {
  Title,
  Text,
  Tooltip,
  Stack,
  Group,
  Anchor,
  useMantineTheme,
  Box,
  Button,
  NumberInput,
  Spoiler,
  Paper,
  SimpleGrid,
  Alert,
  Grid,
  Center,
  Flex,
  Divider,
} from "@mantine/core";
import { IconHelpCircle } from "@tabler/icons-react";
import {
  EnrollState,
  PlanQuoteWithDependents,
  useEnrollContext,
} from "../../EnrollProvider";
import {
  NUMBER_OF_PAY_PERIODS_YEARLY_BY_FREQUENCY,
  PayFrequencyOptions,
  TAX_ADVANTAGED_LOC_NAMES,
  TAX_ADVANTAGED_PLAN_DETAILS,
} from "@/constants";
import { useEffect, useMemo, useState } from "react";
import { CarrierImage } from "@/components";
import { useAtomValue } from "jotai";
import { memberInfoAtom } from "../../API";

type Props = {
  planQuote: PlanQuote;
  onSubmit?: (
    lineOfCoverage: string,
    selectedQuote: PlanQuoteWithDependents,
  ) => void;
};
export const TaxAdvantagedCard = ({ planQuote, onSubmit }: Props) => {
  const { state } = useEnrollContext();
  const theme = useMantineTheme();
  const memberInfo = useAtomValue(memberInfoAtom[0]);
  const plan = planQuote?.plan;
  const limit = planQuote?.tax_advantaged_account_limit;
  const monthlyLimit = limit?.monthly_contribution_limit;

  const [showEditButton, setShowEditButton] = useState<boolean>(false);
  const contributionAmount = useMemo(
    () =>
      state.taxAdvantaged.get(plan.line_of_coverage)
        ?.taxAdvantagedAccountContribution ?? 0,
    [state.taxAdvantaged],
  );

  const [pendingContributionAmount, setPendingContributionAmount] =
    useState<number>(contributionAmount);

  const memberMonthlyLimit =
    plan.line_of_coverage === "hsa"
      ? monthlyLimit - parseFloat(planQuote.employer_contribution)
      : monthlyLimit;
  const overContributing = useMemo(
    () => pendingContributionAmount > memberMonthlyLimit,
    [pendingContributionAmount, memberMonthlyLimit],
  );

  const perPayPeriodAmount = useMemo(() => {
    return (
      (Number(contributionAmount) * 12) /
      NUMBER_OF_PAY_PERIODS_YEARLY_BY_FREQUENCY[memberInfo.pay_frequency]
    ).toFixed(2);
  }, [contributionAmount]);

  const hsaEligiblePlanEnrolled = useMemo(() => {
    return state.medical?.quote?.plan?.hsa_eligible ?? false;
  }, [state]);

  const determinePlanSelection = (plan: Plan, state: EnrollState) => {
    let enableSelection = true;
    let enableSelectionTooltip = "";

    // spend account rules
    const hsa_selection = state.taxAdvantaged.get("hsa")?.quote;
    const healthcare_fsa_selection =
      state.taxAdvantaged.get("healthcare_fsa")?.quote;
    const limited_purpose_fsa_selection = state.taxAdvantaged.get(
      "limited_purpose_fsa",
    )?.quote;

    switch (plan.line_of_coverage) {
      case "hsa":
        if (!hsaEligiblePlanEnrolled) {
          enableSelection = false;
          enableSelectionTooltip =
            "You must be enrolled into a High Deductible Medical plan to be eligible for an HSA.";
        } else if (healthcare_fsa_selection != null) {
          enableSelection = false;
          enableSelectionTooltip =
            "You cannot enroll into an HSA since you enrolled into a Healthcare FSA.";
        }
        break;
      case "healthcare_fsa":
        if (hsa_selection != null) {
          enableSelection = false;
          enableSelectionTooltip =
            "You cannot enroll into a Healthcare FSA since you enrolled into an HSA.";
        } else {
          if (limited_purpose_fsa_selection != null) {
            enableSelection = false;
            enableSelectionTooltip =
              "You cannot enroll into a Healthcare FSA since you enrolled into a Limited Purpose FSA.";
          }
        }
        break;
      case "limited_purpose_fsa":
        if (hsa_selection == null) {
          enableSelection = false;
          enableSelectionTooltip =
            "You must enroll into a HSA in order to be eligible to enroll into a Limited Purpose FSA.";
        }
        break;
    }
    return {
      enableSelection,
      enableSelectionTooltip,
    };
  };

  const { enableSelection, enableSelectionTooltip } = useMemo(() => {
    return determinePlanSelection(plan, state);
  }, [plan, state]);

  const onSubmitHelper = (selection: "waive" | "enroll") => {
    const waive = selection === "waive" || pendingContributionAmount === 0;
    onSubmit(plan.line_of_coverage, {
      quote: waive ? null : planQuote,
      dependents: [],
      taxAdvantagedAccountContribution: waive
        ? null
        : pendingContributionAmount,
    });

    // if user waives hsa, then also waive limited purpose fsa if
    // user has enrolled into it
    if (
      plan.line_of_coverage == "hsa" &&
      waive &&
      state.taxAdvantaged.get("limited_purpose_fsa")?.quote
    ) {
      onSubmit("limited_purpose_fsa", {
        quote: null,
        dependents: [],
        taxAdvantagedAccountContribution: null,
      });
    }

    // show edit button
    setShowEditButton(true);
  };

  const header = (
    <>
      <Text c="gray.7" fw="600" size="md">
        {plan.plan_name}
      </Text>
      {plan.plan_summary_url && (
        <Anchor
          c="gray.7"
          fw="400"
          href={plan.plan_summary_url}
          target="_blank"
          size="md"
          underline="always"
        >
          View Plan Summary
        </Anchor>
      )}
    </>
  );

  return (
    <Stack gap={0}>
      <Group justify="space-between">
        <Group gap="lg">
          <CarrierImage
            h={100}
            w={100}
            carrierId={(plan.group as CarrierGroup).carrier as string}
          />
          {header}
        </Group>
      </Group>
      <Grid my="lg" gutter={{ base: "md", xs: "md", md: "md", xl: "200" }}>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <PlanDetailsSection plan={plan} />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }} c="gray.7" lts="0.2px">
          <Stack>
            <Group>
              <Text style={{ flex: 1 }} fz="lg">
                Your Monthly Contribution
              </Text>
              <NumberInput
                value={contributionAmount}
                min={0}
                hideControls={true}
                decimalScale={2}
                onChange={(value) => {
                  const numericValue =
                    value === ""
                      ? 0
                      : typeof value === "string"
                      ? parseFloat(value)
                      : value;
                  setPendingContributionAmount(numericValue);
                }}
                allowLeadingZeros={false}
                allowNegative={false}
                disabled={!onSubmit || !enableSelection || showEditButton}
                styles={{
                  input: {
                    textAlign: "right",
                  },
                }}
                maw="250px"
                style={{
                  flex: 1,
                }}
                prefix="$"
                error={
                  overContributing ? (
                    <Text>
                      Contribution exceeds maximum limit $ {memberMonthlyLimit}
                    </Text>
                  ) : (
                    ""
                  )
                }
              />
            </Group>
            <Divider mb="md" />
            <Stack>
              <Group justify="space-between">
                <Group gap={"xs"}>
                  <Text fw="bold" fz="lg">
                    Employer Cost
                  </Text>
                  <Tooltip
                    label={
                      <Stack>
                        <Text fz="sm" style={{ whiteSpace: "pre-wrap" }}>
                          {`The amount your employer will contribute to your ${
                            TAX_ADVANTAGED_LOC_NAMES[plan.line_of_coverage]
                          } each month.`}
                        </Text>
                      </Stack>
                    }
                    position="top"
                    withinPortal
                    withArrow
                  >
                    <IconHelpCircle
                      stroke={1.5}
                      size={17.5}
                      color={theme.colors[theme.primaryColor][5]}
                    />
                  </Tooltip>
                </Group>
                <Text fz="lg">$ {planQuote.employer_contribution ?? 0}</Text>
              </Group>
              <Group justify="space-between">
                <Group gap={"xs"}>
                  <Text fw="bold" fz="lg">
                    Your Cost
                  </Text>
                  <Tooltip
                    label={
                      <Stack>
                        <Text fz="sm" style={{ whiteSpace: "pre-wrap" }}>
                          {`The amount you will contribute to your ${
                            TAX_ADVANTAGED_LOC_NAMES[plan.line_of_coverage]
                          } each month.`}
                        </Text>
                      </Stack>
                    }
                    position="top"
                    withinPortal
                    withArrow
                  >
                    <IconHelpCircle
                      stroke={1.5}
                      size={17.5}
                      color={theme.colors[theme.primaryColor][5]}
                    />
                  </Tooltip>
                </Group>
                <Text fz="lg" fw="bold">
                  $ {pendingContributionAmount ?? 0}
                </Text>
              </Group>
              <Group justify="space-between" wrap="nowrap">
                <Stack gap={0}>
                  <Group gap={"xs"}>
                    <Text fw="bold" fz="lg">
                      Monthly Total
                    </Text>
                    <Tooltip
                      label={
                        <Stack>
                          <Text fz="sm">
                            {`The total amount you and your employer contribute to your ${
                              TAX_ADVANTAGED_LOC_NAMES[plan.line_of_coverage]
                            } each month.`}
                          </Text>
                        </Stack>
                      }
                      position="top"
                      withinPortal
                      withArrow
                    >
                      <IconHelpCircle
                        stroke={1.5}
                        size={17.5}
                        color={theme.colors[theme.primaryColor][5]}
                      />
                    </Tooltip>
                  </Group>
                  <Text
                    fz="md"
                    // style={{
                    //   wordBreak: 'break-word',
                    //   overflowWrap: 'break-word'
                    // }}
                  >
                    The monthly maximum is ${" "}
                    {
                      planQuote.tax_advantaged_account_limit
                        .monthly_contribution_limit
                    }{" "}
                    (${" "}
                    {
                      planQuote.tax_advantaged_account_limit
                        .yearly_contribution_limit
                    }{" "}
                    annually)
                  </Text>
                </Stack>
                <Text fz="lg" style={{ flexShrink: 0, alignSelf: "start" }}>
                  ${" "}
                  {(
                    pendingContributionAmount +
                    parseFloat(planQuote.employer_contribution)
                  ).toFixed(2)}
                </Text>
              </Group>
            </Stack>
          </Stack>
        </Grid.Col>
      </Grid>
      {onSubmit &&
        (showEditButton ? (
          <Group justify="flex-end">
            <Button onClick={() => setShowEditButton(false)}>
              Edit Selection
            </Button>
          </Group>
        ) : (
          <Group justify="flex-end">
            <Button variant="outline" onClick={() => onSubmitHelper("waive")}>
              Waive
            </Button>
            <Tooltip label={enableSelectionTooltip} disabled={enableSelection}>
              <Button
                disabled={!enableSelection || overContributing}
                onClick={() => onSubmitHelper("enroll")}
              >
                Confirm Selection
              </Button>
            </Tooltip>
          </Group>
        ))}
    </Stack>
  );
};

const PlanDetailsSection = ({ plan }: { plan: Plan }) => {
  const planDetails = TAX_ADVANTAGED_PLAN_DETAILS[plan.line_of_coverage];

  const items = planDetails.map((item) => {
    return (
      <li>
        <Text fw="bold" span>{`${item.header}: `}</Text>
        <Text span>{item.content}</Text>
      </li>
    );
  });
  return (
    <Paper withBorder={true} p="md">
      <Text fz="md" fw="bold">
        Eligibility Requirements and Additional Information
      </Text>
      <Spoiler
        c="gray.7"
        maxHeight={95}
        showLabel="See more info"
        hideLabel="See less info"
        styles={{
          control: {
            textDecoration: "underline",
          },
        }}
      >
        <ul>{items}</ul>
      </Spoiler>
    </Paper>
  );
};
