import { useApiClient } from "@/helpers/hooks";
import { APIClient } from "@/stores/APIClient";
import { Member, WritableMember, WritablePayRate } from "@/types/api";
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

export const useCreateEmployeeMutation = (): UseMutationResult<Member> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      client,
      data,
    }: {
      client: APIClient;
      data: WritableMember;
    }) => client.createEmployee(data),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["members"] });
    },
  });
};

export const useUpdateEmployeeMutation = (): UseMutationResult<Member> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      client,
      data,
      memberId,
    }: {
      client: APIClient;
      memberId: string;
      data: Partial<WritableMember>;
    }) => client.updateMember(memberId, data),
    onSuccess(_, { memberId }) {
      queryClient.invalidateQueries({ queryKey: ["members", memberId] });
    },
  });
};

export const useCreatePayRateMutation = () => {
  const queryClient = useQueryClient();
  const client = useApiClient();
  return useMutation({
    mutationFn: ({ data }: { data: WritablePayRate }) =>
      client.createPayRate(data),
    onSuccess({ member }) {
      queryClient.invalidateQueries({ queryKey: ["pay_rates", member] });
    },
  });
};
