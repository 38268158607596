import { PayPeriodOptions } from "@/constants";
import { toISODateString } from "@/helpers/string";
import { DeepPartial } from "@/types/utils";
import {
  Button,
  Grid,
  Group,
  LoadingOverlay,
  Modal,
  ModalProps,
  NumberInput,
  Select,
  Stack,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useCreatePayRateMutation } from "../EmployeeModal/hooks";
import { CreatePayRateType, PayRateSchema } from "./form";
import { usePayRate, usePayRates, useUpdatePayRateMutation } from "./hooks";

interface Props extends Pick<ModalProps, "opened" | "onClose"> {
  member: string;
  payRate?: string;
}

export const PayRateDialog = ({
  payRate,
  member,
  onClose,
  opened,
  ...rest
}: Props) => {
  const { data, isLoading } = usePayRate(payRate, {
    refetchOnWindowFocus: false,
  });
  const { data: payRatesData, isLoading: isLoadingPayRates } =
    usePayRates(member);
  const sortedPayRates = payRatesData?.results.sort((a, b) =>
    a.effective_start < b.effective_start ? 1 : -1,
  );

  const firstAvailableEffectiveStart = sortedPayRates?.[0]?.effective_start;

  const form = useForm<
    DeepPartial<CreatePayRateType>,
    (EditEmployeeType: DeepPartial<CreatePayRateType>) => CreatePayRateType
  >({
    validate: zodResolver(
      PayRateSchema.refine(
        (schema) => {
          const effectiveStart = toISODateString(schema.effective_start);
          const payRateIndex = sortedPayRates?.findIndex(
            ({ id }) => id === payRate,
          );

          // Ensure pay rate is after the previous pay rate or that it is the first pay rate
          const payRateAfterPreviousRate =
            !sortedPayRates[payRateIndex + 1]?.effective_start ||
            sortedPayRates[payRateIndex + 1]?.effective_start < effectiveStart;

          // Ensure pay rate is before the next pay rate or that it is the last pay rate
          const payRateBeforeNextRate =
            !sortedPayRates[payRateIndex - 1]?.effective_start ||
            sortedPayRates[payRateIndex - 1]?.effective_start > effectiveStart;

          return payRateAfterPreviousRate && payRateBeforeNextRate;
        },
        {
          message: "Pay rate may not overlap with another pay rate",
          path: ["effective_start"],
        },
      ),
    ),
  });

  const createPayRateMutation = useCreatePayRateMutation();
  const updatePayRateMutation = useUpdatePayRateMutation();
  const { setValues, reset } = form;

  useEffect(() => {
    if (!payRate) {
      reset();
      setValues({
        effective_start: dayjs(firstAvailableEffectiveStart)
          .add(firstAvailableEffectiveStart ? 1 : 0, "day")
          .toDate(),
      });
    }
  }, [firstAvailableEffectiveStart, payRate, reset, setValues]);

  useEffect(() => {
    if (!isLoading && data) {
      setValues({
        ...data,
        effective_start: dayjs(data.effective_start).toDate(),
        amount: Number(data.amount),
      });
    }
  }, [data, isLoading, setValues]);

  const onSubmit = form.onSubmit(async (values) => {
    const { effective_start, amount, period } = values;

    const data = {
      amount,
      effective_start: toISODateString(effective_start),
      period,
      member,
    };

    if (payRate) {
      return updatePayRateMutation.mutate(
        {
          payRate,
          data,
        },
        {
          onSuccess: () => {
            notifications.show({
              title: "Pay rate update",
              message: `Pay rate has successfully been updated.`,
            });
            form.reset();
            onClose();
          },
        },
      );
    } else {
      return createPayRateMutation.mutate(
        {
          data,
        },
        {
          onSuccess: () => {
            notifications.show({
              title: "Pay rate created",
              message: `Pay rate has successfully been created.`,
            });
            form.reset();
            onClose();
          },
        },
      );
    }
  });

  return (
    <Modal
      {...rest}
      title={payRate ? "Edit Rate" : "Create Rate"}
      onClose={onClose}
      opened={opened}
    >
      <form onSubmit={onSubmit}>
        <Stack pos="relative">
          <LoadingOverlay
            visible={isLoading}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />
          <Grid>
            <Grid.Col>
              <NumberInput
                label="Pay"
                placeholder="$"
                prefix="$"
                hideControls
                thousandSeparator=","
                {...form.getInputProps("amount")}
              />
            </Grid.Col>
            <Grid.Col>
              <Select
                label="Period"
                data={PayPeriodOptions}
                {...form.getInputProps("period")}
              />
            </Grid.Col>
            <Grid.Col>
              <DateInput
                label="Effective Start"
                placeholder="Effective Start"
                {...form.getInputProps("effective_start")}
                popoverProps={{
                  withinPortal: true,
                }}
              />
            </Grid.Col>
          </Grid>
          <Group justify="flex-end">
            <Button
              variant="outline"
              onClick={onClose}
              disabled={createPayRateMutation.isPending}
            >
              Cancel
            </Button>
            <Button
              disabled={isLoadingPayRates}
              type="submit"
              loading={createPayRateMutation.isPending}
            >
              Save
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};
