import { BiologicalSexOptions } from "@/constants";
import { useApiClient } from "@/helpers/hooks";
import { parseDate, toISODateString } from "@/helpers/string";
import { RoleMapping, Roles, WritableMember } from "@/types/api";
import { DeepPartial } from "@/types/utils";
import {
  Button,
  Divider,
  Grid,
  Group,
  LoadingOverlay,
  Modal,
  ModalProps,
  Radio,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconMail } from "@tabler/icons-react";
import { useEffect } from "react";
import { OptionalRadioGroup } from "../Form/OptionalRadioGroup";
import { useGetMember } from "../ViewEmployeeDetail/hooks";
import { EditEmployeeDetails, EditEmployeeDetailsSchema } from "./form";
import { useUpdateEmployeeMutation } from "./hooks";
import classes from "./styles.module.css";

type Props = Pick<ModalProps, "opened" | "onClose"> & {
  employeeId: string;
};

export const EditEmployeeDetailsModal = ({
  opened,
  onClose,
  employeeId,
}: Props) => {
  const employeeMutation = useUpdateEmployeeMutation();
  const client = useApiClient();
  const form = useForm<
    DeepPartial<EditEmployeeDetails>,
    (EditEmployeeType: EditEmployeeDetails) => EditEmployeeDetails
  >({
    initialValues: {},
    validate: zodResolver(EditEmployeeDetailsSchema),
  });
  const { data, isLoading } = useGetMember(employeeId!, {
    refetchOnWindowFocus: false,
  });
  const { setValues } = form;
  useEffect(() => {
    if (!isLoading) {
      setValues({
        ...data,
        dob: parseDate(data.dob),
        hire_date: parseDate(data.hire_date),
        termination_date: data.termination_date
          ? parseDate(data.termination_date)
          : undefined,
        ssn: `***-**-${data.ssn_last_four}`,
        tobacco_usage: data.tobacco_usage,
      });
    }
  }, [data, isLoading, setValues]);

  const onSubmit = form.onSubmit(async (values) => {
    const {
      ssn,
      dob,
      first_name,
      last_name,
      email,
      biological_sex,
      phone_number,
      hire_date,
      termination_date,
      role,
      tobacco_usage,
    } = EditEmployeeDetailsSchema.parse(values);
    return employeeMutation.mutate(
      {
        client,
        memberId: employeeId,
        data: {
          first_name,
          last_name,
          email,
          phone_number,
          biological_sex,
          tobacco_usage: tobacco_usage,
          dob: toISODateString(dob),
          hire_date: toISODateString(hire_date),
          termination_date: termination_date
            ? toISODateString(termination_date)
            : undefined,
          ...(ssn.indexOf("*") >= 0 ? {} : { ssn }),
          role,
        } as Partial<WritableMember>,
      },
      {
        onSuccess: () => {
          notifications.show({
            title: "Employee Updated",
            message: `Employee ${values.first_name} ${values.last_name} has successfully been updated.`,
          });
          onClose();
        },
      },
    );
  });

  return (
    <Modal.Root opened={opened} onClose={onClose} centered size="540">
      <Modal.Overlay />
      <Modal.Content px="md" pt="md" radius="md">
        <Modal.Body p={"md"}>
          <Stack gap={"xs"} mb={32}>
            <Modal.Title classNames={{ title: classes.modalTitle }}>
              Employee Details
            </Modal.Title>
            <Text fw={400} c="gray.7">
              Please contact your benefits administrator to change other fields.
            </Text>
          </Stack>
          <form onSubmit={onSubmit}>
            <Stack pos="relative">
              <LoadingOverlay
                visible={isLoading}
                zIndex={1000}
                overlayProps={{ radius: "sm", blur: 2 }}
              />
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="First Name"
                    placeholder="First Name"
                    {...form.getInputProps("first_name")}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Last Name"
                    placeholder="Last Name"
                    {...form.getInputProps("last_name")}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Select
                    label="Sex"
                    placeholder="Sex"
                    data={BiologicalSexOptions}
                    {...form.getInputProps("biological_sex")}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Social Security Number"
                    placeholder="Social Security Number"
                    {...form.getInputProps("ssn")}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <DateInput
                    label="Hire Date"
                    placeholder="Hire Date"
                    {...form.getInputProps("hire_date")}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <DateInput
                    label="Termination Date"
                    placeholder="Termination Date"
                    {...form.getInputProps("termination_date")}
                  />
                </Grid.Col>
              </Grid>
              <TextInput
                label="Email"
                placeholder="Email"
                leftSection={<IconMail size={16} />}
                {...form.getInputProps("email")}
              />
              <TextInput
                label="Phone Number"
                placeholder="Phone Number"
                {...form.getInputProps("phone_number")}
              />
              <DateInput
                label="Date of Birth"
                placeholder="Date of Birth"
                {...form.getInputProps("dob")}
              />
              <Select
                label="Role"
                placeholder="Role"
                data={Roles.map((value) => ({
                  label: RoleMapping[value],
                  value,
                }))}
                {...form.getInputProps("role")}
              />
              <OptionalRadioGroup
                label="Tobacco Usage"
                {...form.getInputProps("tobacco_usage")}
              >
                <Group>
                  <Radio value="true" label="Yes" />
                  <Radio value="false" label="No" />
                </Group>
              </OptionalRadioGroup>

              <Divider my={4} />
              <Group justify="flex-end">
                <Button
                  variant="primary"
                  type="submit"
                  loading={employeeMutation.isPending}
                >
                  Update
                </Button>
              </Group>
            </Stack>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
