import { useAtom, useAtomValue } from "jotai";
import { getEligiblePlansAtom, getEnrollmentAtom } from "../../API";
import { useEffect, useMemo, useState } from "react";
import { LineOfCoverage, PlanQuote } from "@/types/api";
import {
  PlanQuoteWithDependents,
  useEnrollContext,
} from "../../EnrollProvider";
import { TAX_ADVANTAGED_LOC_NAMES, TAX_ADVANTAGED_LOCS } from "@/constants";
import { Accordion, Group, Stack, Title, Text } from "@mantine/core";
import { TaxAdvantagedCard } from "./TaxAdvantagedCard";

export const TaxAdvantagedStep = () => {
  const [openedPanels, setOpenedPanels] = useState([]);
  const [, fetchEligiblePlans] = useAtom(getEligiblePlansAtom);
  const [eligiblePlans, setEligiblePlans] = useState<PlanQuote[]>([]);
  const enrollment = useAtomValue(getEnrollmentAtom[0]);
  const {
    state: { taxAdvantaged, medical },
    dispatch,
  } = useEnrollContext();

  const onSelectHelper = (
    lineOfCoverage: LineOfCoverage,
    selectedQuote: PlanQuoteWithDependents | null,
  ) => {
    dispatch({
      type: "set-tax-advantaged",
      payload: new Map(taxAdvantaged.set(lineOfCoverage, selectedQuote)),
    });
  };

  useEffect(() => {
    fetchEligiblePlans([
      {
        body: { dependents: medical?.dependents ?? [] },
        enrollmentId: enrollment.id,
      },
    ]).then((quotes) => {
      setEligiblePlans(quotes);
    });
  }, [enrollment, fetchEligiblePlans]);

  const planQuotes = useMemo(
    () =>
      eligiblePlans.filter((planQuote) =>
        TAX_ADVANTAGED_LOCS.includes(planQuote.plan.line_of_coverage),
      ),
    [eligiblePlans],
  );

  const hsaEligiblePlanEnrolled = useMemo(() => {
    return medical?.quote?.plan?.hsa_eligible ?? false;
  }, [medical]);

  return (
    <Stack gap={24}>
      <Title size="h3">Your Health Spending Account Options</Title>
      <Accordion
        multiple
        variant="separated"
        value={openedPanels}
        onChange={setOpenedPanels}
        styles={(theme) => ({
          item: {
            border: "1px solid",
            borderColor: theme.colors.gray[3],
            borderRadius: theme.radius.md,
          },
        })}
      >
        {planQuotes
          .sort(
            (a, b) =>
              TAX_ADVANTAGED_LOCS.indexOf(a.plan.line_of_coverage) -
              TAX_ADVANTAGED_LOCS.indexOf(b.plan.line_of_coverage),
          )
          .filter((planQuote) =>
            // if ee not enrolled into hsa eligible plan, then don't show the hsa card
            // and limited purpose fsa card
            (planQuote.plan.line_of_coverage === "hsa" &&
              !hsaEligiblePlanEnrolled) ||
            (planQuote.plan.line_of_coverage === "limited_purpose_fsa" &&
              !hsaEligiblePlanEnrolled)
              ? false
              : true,
          )
          .map((planQuote) => (
            <Accordion.Item value={planQuote.plan.line_of_coverage}>
              <Accordion.Control>
                <Group wrap="nowrap" justify="space-between">
                  <Text size="xl" fw={700}>
                    {TAX_ADVANTAGED_LOC_NAMES[planQuote.plan.line_of_coverage]}
                  </Text>
                  <StatusTag
                    plan={
                      taxAdvantaged.get(planQuote.plan.line_of_coverage)?.quote
                    }
                  />
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <TaxAdvantagedCard
                  planQuote={planQuote}
                  onSubmit={onSelectHelper}
                />
              </Accordion.Panel>
            </Accordion.Item>
          ))}
      </Accordion>
    </Stack>
  );
};

const StatusTag = ({ plan }: { plan?: PlanQuote }) => {
  if (plan === undefined) {
    return (
      <Text
        style={(theme) => ({
          color: theme.colors.orange[3],
        })}
        pr={10}
      >
        Pending Selection
      </Text>
    );
  } else if (plan === null) {
    return (
      <Text
        style={(theme) => ({
          color: theme.colors.red[3],
        })}
        pr={10}
      >
        Waived
      </Text>
    );
  }

  return (
    <Text
      style={(theme) => ({
        color: theme.colors[theme.primaryColor][5],
      })}
      pr={10}
    >
      Enrolled
    </Text>
  );
};
