import { MantineProvider, MantineTheme, Tabs } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { StytchProvider } from "@stytch/react";
import { StytchUIClient } from "@stytch/vanilla-js";
import { useQuery } from "@tanstack/react-query";
import { atom, useAtomValue } from "jotai";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { ClaspAuthMeta, ClaspEmployerConfig } from ".";
import { AppWrapper } from "./components/AppWrapper";
import { AppAuthenticate } from "./components/Auth/AppAuthenticate";
import { AppLogin } from "./components/Auth/AppLogin";
import { Signup } from "./components/Auth/Signup";
import ErrorBoundary from "./components/ErrorBoundary";
import HostedClaspEmployee from "./components/HostedClaspEmployee";
import HostedClaspEmployer from "./components/HostedClaspEmployer";
import Login from "./components/Login";
import { LoginLoader } from "./components/Login/Login";
import { ViewEmployeeDetail } from "./components/ViewEmployeeDetail/ViewEmployeeDetail";
import { ViewEmployees } from "./components/ViewEmployees";
import { ViewEnrollmentDetail } from "./components/ViewEnrollmentDetail";
import { ViewPendingEnrollments } from "./components/ViewPendingEnrollments";
import { ViewReports } from "./components/ViewReports";
import { theme } from "./constants/theme";
import { isEmbedded } from "./helpers";
import { useApiClient } from "./helpers/hooks";
import { ClaspWrapper } from "./components";
import { PartnerConfig } from "./types/api";

export const authAtom = atom<ClaspAuthMeta>({ accessToken: "", expiresAt: "" });
export const themeAtom = atom<MantineTheme>(theme);

const EmployerComponentWrapper = () => {
  const navigate = useNavigate();

  const partnerConfig = useOutletContext() as PartnerConfig;
  const location = useLocation();
  const client = useApiClient();
  const query = useQuery({
    queryKey: ["employer"],
    queryFn: () =>
      client.getEmployer((client.config as ClaspEmployerConfig).employerId),
  });
  const tabValue = location.pathname.split("/")[2];

  const theme =
    JSON.parse(localStorage.getItem("theme")) ?? partnerConfig.theme;

  return isEmbedded() && query.data?.enable_enrollment_approval ? (
    <Tabs
      value={tabValue}
      variant="outline"
      onChange={(value) => navigate(`/employer/${value}`)}
      keepMounted
    >
      <Tabs.List>
        <Tabs.Tab value="view-plans">Summary</Tabs.Tab>
        <Tabs.Tab value="view-enrollments">Manage Enrollments</Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value="view-plans" pt="xl">
        <Outlet context={partnerConfig} />
      </Tabs.Panel>
      <Tabs.Panel value="view-enrollments" pt="xl">
        <Outlet context={partnerConfig} />
      </Tabs.Panel>
    </Tabs>
  ) : (
    <ClaspWrapper theme={theme}>
      <Outlet context={partnerConfig} />
    </ClaspWrapper>
  );
};

const router = createBrowserRouter([
  {
    element: <AppWrapper />,
    children: [
      {
        path: "/employer/:tabValue?",
        element: <EmployerComponentWrapper />,
        children: [
          {
            index: true,
            element: <Navigate to="/employer/view-plans" />,
          },
          {
            path: "view-plans",
            element: <HostedClaspEmployer />,
          },
          {
            path: "view-employees",
            element: <ViewEmployees />,
          },
          {
            path: "view-employees/:employeeId",
            element: <ViewEmployeeDetail />,
          },
          {
            path: "view-enrollments",
            element: <ViewPendingEnrollments />,
          },
          {
            path: "view-enrollments/:enrollmentId",
            element: <ViewEnrollmentDetail />,
          },
          {
            path: "reports",
            element: <ViewReports />,
          },
        ],
      },
      {
        path: "/member",
        children: [
          {
            index: true,
            element: <Navigate to="/member/view-benefits" />,
          },
          {
            path: "view-benefits",
            element: <HostedClaspEmployee claspStateOverride="employee_home" />,
          },
          {
            path: "update-benefits",
            element: <HostedClaspEmployee claspStateOverride="qle" />,
          },
        ],
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorBoundary />,
    loader: LoginLoader,
  },
  {
    path: "/app/login",
    element: <AppLogin />,
  },
  {
    path: "/app/authenticate",
    element: <AppAuthenticate />,
  },
  {
    path: "/app/signup",
    element: <Signup />,
  },
  {
    path: "/",
    element: (
      <Navigate to={localStorage.getItem("component") || "/app/login"} />
    ),
    errorElement: <ErrorBoundary />,
  },
]);

const App = () => {
  // Remove subdomain and check if it is a part of withclasp.com. If not only
  // allow cookies to be used on specific subdomain.
  const hostParts = window.location.host.split(".");
  const domain = hostParts.slice(1).join(".");
  let cookieOptions;
  if (domain === "withclasp.com") {
    cookieOptions = { availableToSubdomains: true, domain: ".withclasp.com" };
  } else {
    cookieOptions = { availableToSubdomains: false };
  }
  const stytchClient = new StytchUIClient(
    import.meta.env.VITE_STYTCH_PUBLIC_TOKEN,
    {
      cookieOptions,
    },
  );
  const currentTheme = useAtomValue(themeAtom);

  return (
    <StytchProvider stytch={stytchClient}>
      <MantineProvider theme={currentTheme}>
        <ModalsProvider>
          <RouterProvider router={router} />
        </ModalsProvider>
      </MantineProvider>
    </StytchProvider>
  );
};

export default App;
