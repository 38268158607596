import {
  CUSTOM_VOLUME_LOCS,
  SUPPLEMENTAL_LOCS,
  TAX_ADVANTAGED_LOCS,
} from "@/constants";
import { partnerConfigAtom } from "@/stores";
import {
  Dependent,
  Employer,
  EnrollmentPlan,
  EnrollmentPlanDependent,
  LineOfCoverage,
  Plan,
  PlanQuote,
} from "@/types/api";
import { Button, Group, Stack, Stepper, Text, Title } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { z } from "zod";
import {
  employerPlansAtom,
  getEligiblePlansAtom,
  getEnrollmentAtom,
  memberDependentsAtom,
  memberInfoAtom,
  saveAndSubmitEnrollmentAtom,
} from "./API";
import AdditionalInfo from "./AdditionalInfo";
import { ConfirmDetails } from "./ConfirmDetails";
import {
  ConfirmMemberInfoFormDataSchema,
  ConfirmMemberInfoFormProvider,
  useConfirmMemberInfoForm,
} from "./ConfirmDetails/ConfirmMemberInfoForm";
import { updateMemberSsnAndBiologicalSexAtom } from "./ConfirmDetails/ConfirmMemberInfoForm/hooks";
import { CoverageStep } from "./CoverageStep";
import { SupplementalStep } from "./CoverageStep/SupplementalStep";
import {
  VoluntarySupplementalBenefitCardFormProvider,
  VoluntarySupplementalBenefitElection,
  useVoluntarySupplementalBenefitCardForm,
  useVoluntarySupplementalBenefitCardFormInitialValues,
} from "./CoverageStep/VoluntarySupplementalBenefitCard/form";
import { PlanQuoteWithDependents, useEnrollContext } from "./EnrollProvider";
import { EmployeeEnrollFinalReview } from "./FinalReview";
import {
  AdditionalInfoForm,
  AdditionalInfoFormSchema,
  CoverageWaiverSchema,
  PrimaryCareSelectionSchema,
} from "./form";
import { TaxAdvantagedStep } from "./CoverageStep/TaxAdvantagedStep";

export const EnrollStepper = ({ onCompleted }: { onCompleted: () => void }) => {
  const [submissionMutation, saveAndSubmitEnrollment] = useAtom(
    saveAndSubmitEnrollmentAtom,
  );

  const dependents = useAtomValue(memberDependentsAtom);
  const memberInfo = useAtomValue(memberInfoAtom[0]);
  // Force hydration of this atom in the parent to ensure
  // it is present in EmployeeEnrollFinalReview. Without this
  // the stepper will not go to the final review step on the first
  // attempt
  const partnerConfig = useAtomValue(partnerConfigAtom[0]);
  const employer = memberInfo.employer as Employer;
  const employerName = employer.trade_name || employer.legal_name;
  const enrollment = useAtomValue(getEnrollmentAtom[0]);
  const showDescriptions = useMediaQuery("(min-width: 75em)");
  const [, memberMutation] = useAtom(updateMemberSsnAndBiologicalSexAtom);

  /**
   * selectedMedical === undefined
   * - either they don't have a medical option or they didn't select it
   *
   * selectedMedical === null
   * - they waived it
   */
  const {
    state: { medical, dental, vision, supplemental, taxAdvantaged },
    dispatch,
    step,
    setStep,
  } = useEnrollContext();

  const [employerPlans] = useAtom(employerPlansAtom);
  const [, fetchEligiblePlans] = useAtom(getEligiblePlansAtom);
  const [lineOfCoverages, setLineOfCoverages] = useState(
    new Set(employerPlans.map((plan) => plan.line_of_coverage)),
  );

  // Determine whether we need additional information such as
  // waive reasons or PCP details
  let waivedMainLOCs = false;
  for (const { quote: planQuote, dependents: selectedDependents } of [
    medical,
    dental,
    vision,
  ]) {
    if (planQuote === undefined) {
      continue;
    }

    if (planQuote === null) {
      waivedMainLOCs = true;
      break;
    }
    if (dependents.length !== selectedDependents.length) {
      waivedMainLOCs = true;
      break;
    }
  }
  const requiresAdditionalInformation =
    medical.quote?.plan?.requires_primary_care_provider ||
    dental.quote?.plan?.requires_primary_care_provider ||
    waivedMainLOCs;

  const hsaEligiblePlanEnrolled = useMemo(() => {
    return medical?.quote?.plan?.hsa_eligible ?? false;
  }, [medical]);

  useEffect(() => {
    if (!hsaEligiblePlanEnrolled) {
      const waivedQuote = {
        quote: null,
        dependents: [],
        taxAdvantagedAccountContribution: null,
      };
      // waive hsa
      if (lineOfCoverages.has("hsa")) {
        dispatch({
          type: "set-tax-advantaged",
          payload: new Map(taxAdvantaged.set("hsa", waivedQuote)),
        });
      }
      if (lineOfCoverages.has("limited_purpose_fsa")) {
        // waive limited purpose fsa
        dispatch({
          type: "set-tax-advantaged",
          payload: new Map(
            taxAdvantaged.set("limited_purpose_fsa", waivedQuote),
          ),
        });
      }
    } else {
      // reset hsa/limited purpose fsa enrollment
      if (lineOfCoverages.has("hsa")) {
        dispatch({
          type: "set-tax-advantaged",
          payload: new Map(taxAdvantaged.set("hsa", null)),
        });
      }
      if (lineOfCoverages.has("limited_purpose_fsa")) {
        dispatch({
          type: "set-tax-advantaged",
          payload: new Map(taxAdvantaged.set("limited_purpose_fsa", null)),
        });
      }
    }
  }, [hsaEligiblePlanEnrolled]);

  const showTaxAdvantagedStep = useMemo(() => {
    const taxAdvantagedLocs = TAX_ADVANTAGED_LOCS.filter((loc) =>
      lineOfCoverages.has(loc),
    );

    // no tax advantaged locs
    if (taxAdvantagedLocs.length == 0) return false;

    // if member not enrolled into hdhp and the only tax advantaged locs
    // are hsa and limited purpose
    const hsaAndLimitedPurposeOnly = taxAdvantagedLocs.every((loc) =>
      ["hsa", "limited_purpose_fsa"].includes(loc),
    );
    if (!hsaEligiblePlanEnrolled && hsaAndLimitedPurposeOnly) {
      return false;
    }

    return true;
  }, [taxAdvantaged, lineOfCoverages]);

  // When making changes to a previously submitted enrollment we should
  // initialize the previous selections.
  useEffect(() => {
    const updateLineOfCoverages = async () => {
      const quotes = await fetchEligiblePlans([
        {
          body: { dependents: [] },
          enrollmentId: enrollment.id,
        },
      ]);
      setLineOfCoverages(
        new Set(quotes.map(({ plan }) => plan.line_of_coverage)),
      );
    };
    const setSelectedPlanQuotes = async () => {
      const enrollmentPlanByLine = new Map();
      enrollment.enrolled_plans.forEach((enrolledPlan) => {
        if (enrolledPlan.effective_start >= enrollment.change_date) {
          enrollmentPlanByLine[enrolledPlan.plan.line_of_coverage] =
            enrolledPlan;
        }
      });
      for (const lineOfCoverage of ["medical", "dental", "vision"] as const) {
        if (lineOfCoverages.has(lineOfCoverage)) {
          dispatch({
            type: `set-${lineOfCoverage}`,
            payload: {
              quote: await generatePlanQuoteFromEnrollmentPlan(
                enrollmentPlanByLine[lineOfCoverage],
                enrollment.id,
                fetchEligiblePlans,
              ),
              dependents: getEnrollmentPlanDependentIds(
                enrollmentPlanByLine[lineOfCoverage],
              ),
            },
          });
        }
      }

      if (SUPPLEMENTAL_LOCS.some((loc) => lineOfCoverages.has(loc))) {
        const supplementalEntries = await Promise.all(
          SUPPLEMENTAL_LOCS.map(async (lineOfCoverage) => {
            const quote = await generatePlanQuoteFromEnrollmentPlan(
              enrollmentPlanByLine[lineOfCoverage],
              enrollment.id,
              fetchEligiblePlans,
            );
            const dependents = getEnrollmentPlanDependentIds(
              enrollmentPlanByLine[lineOfCoverage],
            );
            return [lineOfCoverage, { quote, dependents }] as const;
          }),
        );

        dispatch({
          type: "set-supplemental",
          payload: new Map(supplementalEntries),
        });
      }
      CUSTOM_VOLUME_LOCS.forEach((lineOfCoverage) => {
        if (enrollmentPlanByLine[lineOfCoverage]) {
          const enrollmentPlan = enrollmentPlanByLine[
            lineOfCoverage
          ] as EnrollmentPlan;
          voluntaryForm.setValues({
            [lineOfCoverage]: [
              {
                entityId: memberInfo.id,
                volume: Number(enrollmentPlan.volume).toString(),
              },
              ...(
                enrollmentPlan.enrolled_dependents as EnrollmentPlanDependent[]
              ).map((enrolledDependent) => ({
                entityId: (enrolledDependent.dependent as Dependent).id,
                volume: Number(enrolledDependent.volume).toString(),
                memberRelationship: (enrolledDependent.dependent as Dependent)
                  .member_relationship,
              })),
            ],
          });
        }
      });

      if (TAX_ADVANTAGED_LOCS.some((loc) => lineOfCoverages.has(loc))) {
        const taxAdvantagedEntries = await Promise.all(
          TAX_ADVANTAGED_LOCS.map(async (lineOfCoverage) => {
            const quote = await generatePlanQuoteFromEnrollmentPlan(
              enrollmentPlanByLine[lineOfCoverage],
              enrollment.id,
              fetchEligiblePlans,
            );
            const dependents = getEnrollmentPlanDependentIds(
              enrollmentPlanByLine[lineOfCoverage],
            );
            const enrollmentPlan = enrollmentPlanByLine[
              lineOfCoverage
            ] as EnrollmentPlan;
            const memberContribution = enrollmentPlan.member_contribution;
            return [
              lineOfCoverage,
              { quote, dependents, memberContribution },
            ] as const;
          }),
        );

        dispatch({
          type: "set-tax-advantaged",
          payload: new Map(taxAdvantagedEntries),
        });
      }
    };
    updateLineOfCoverages();
    if (enrollment.enrolled_plans.length !== 0) {
      setSelectedPlanQuotes();
    }
  }, [enrollment]);

  const additionalInfoForm = useForm<AdditionalInfoForm>({
    initialValues: { primaryCareSelections: [], coverageWaivers: [] },
    validate: zodResolver(AdditionalInfoFormSchema),
  });

  const generateConfirmMemberInfoFormInitialValues = () => {
    return {
      biological_sex: memberInfo.biological_sex,
      ssn: memberInfo.ssn_last_four ? `***-**-${memberInfo.ssn_last_four}` : "",
      tobacco_usage: memberInfo.tobacco_usage,
    } as const;
  };

  const confirmMemberInfoForm = useConfirmMemberInfoForm({
    initialValues: generateConfirmMemberInfoFormInitialValues(),
    validate: zodResolver(ConfirmMemberInfoFormDataSchema),
  });

  const voluntaryInitialValues =
    useVoluntarySupplementalBenefitCardFormInitialValues();
  const voluntaryForm = useVoluntarySupplementalBenefitCardForm({
    initialValues: voluntaryInitialValues,
  });

  const { values, setInitialValues } = voluntaryForm;

  // CLA-360: Reload form data after adding a dependent
  useEffect(() => {
    // This works under assumption that voluntary_critical_illness and voluntary_life will always have same number items
    const entityIds = values.voluntary_critical_illness.map(
      ({ entityId }) => entityId,
    );

    const newEntityElections =
      voluntaryInitialValues.voluntary_critical_illness.filter(
        ({ entityId }) => !entityIds.includes(entityId),
      );

    if (newEntityElections.length > 0) {
      setInitialValues({
        voluntary_critical_illness: [
          ...values.voluntary_critical_illness,
          ...newEntityElections,
        ],
        voluntary_life: [...values.voluntary_life, ...newEntityElections],
        voluntary_short_term_disability: [
          ...values.voluntary_short_term_disability,
          ...newEntityElections,
        ],
      });
      voluntaryForm.reset();
    }
  }, [voluntaryInitialValues, values, setInitialValues]);

  const onConfirmDetailsNext = async () => {
    const { biological_sex, ssn, tobacco_usage } = confirmMemberInfoForm.values;

    if (employer.has_tobacco_rated_plan && tobacco_usage == null) {
      confirmMemberInfoForm.setFieldError("tobacco_usage", "Required Field");
      return;
    }

    if (!confirmMemberInfoForm.isDirty()) {
      onNext();
    } else {
      await memberMutation([
        {
          biological_sex,
          ...(ssn.indexOf("*") >= 0 ? {} : { ssn }),
          tobacco_usage: tobacco_usage,
        },
      ]);

      notifications.show({
        title: "Member Updated",
        message: `Member has successfully been updated.`,
      });

      onNext();
    }
  };

  const onNext = () => {
    setStep(step + 1);
    window.scrollTo(0, 0);
  };
  const onPrevious = () => {
    setStep(step - 1);
    window.scrollTo(0, 0);
  };

  const onSubmit = async () => {
    const coverages = [];

    const primaryCareSelectionMap = new Map(
      additionalInfoForm.values.primaryCareSelections.map((pcpSelection) => [
        JSON.stringify([
          pcpSelection.plan,
          pcpSelection.member || pcpSelection.dependent,
        ]),
        pcpSelection,
      ]),
    );
    const coverageWaiverMap = new Map(
      additionalInfoForm.values.coverageWaivers.map((coverageWaiver) => [
        coverageWaiver.lineOfCoverage,
        coverageWaiver,
      ]),
    );

    lineOfCoverages.has("medical") &&
      coverages.push(
        makeCoverageSubmission(
          "medical",
          medical.quote,
          medical.dependents,
          coverageWaiverMap,
          primaryCareSelectionMap,
          memberInfo.id,
        ),
      );
    lineOfCoverages.has("dental") &&
      coverages.push(
        makeCoverageSubmission(
          "dental",
          dental.quote,
          dental.dependents,
          coverageWaiverMap,
          primaryCareSelectionMap,
          memberInfo.id,
        ),
      );
    lineOfCoverages.has("vision") &&
      coverages.push(
        makeCoverageSubmission(
          "vision",
          vision.quote,
          vision.dependents,
          coverageWaiverMap,
          primaryCareSelectionMap,
          memberInfo.id,
        ),
      );
    supplemental.forEach((selectedPlan, lineOfCoverage) => {
      if (CUSTOM_VOLUME_LOCS.some((loc) => loc === lineOfCoverage)) {
        const coverage = makeVolumeCoverageSubmission(
          lineOfCoverage,
          selectedPlan.quote,
          voluntaryForm.values[lineOfCoverage],
        );
        if (coverage) coverages.push(coverage);
      } else if (selectedPlan.quote) {
        coverages.push(
          makeCoverageSubmission(
            lineOfCoverage,
            selectedPlan.quote,
            selectedPlan.dependents,
            coverageWaiverMap,
            primaryCareSelectionMap,
            memberInfo.id,
          ),
        );
      }
    });
    taxAdvantaged.forEach((selectedPlan, lineOfCoverage) => {
      const coverage = makeTaxAdvantagedAccountSubmission(
        lineOfCoverage,
        selectedPlan,
      );
      if (coverage) coverages.push(coverage);
    });

    await saveAndSubmitEnrollment([
      { enrollmentId: enrollment.id, body: { coverages } },
    ]);
    notifications.show({
      title: "Enrollment Submitted",
      message: `We've received your changes and are awaiting admin approval! 
        We will notify you if any further actions are required.`,
    });
    // Clear hash
    setStep(null);
    onCompleted();
  };

  const stepTitles = [
    "Confirm Member Details",
    lineOfCoverages.has("medical") ? "Medical" : null,
    lineOfCoverages.has("dental") ? "Dental" : null,
    lineOfCoverages.has("vision") ? "Vision" : null,
    SUPPLEMENTAL_LOCS.some((loc) => lineOfCoverages.has(loc))
      ? "Supplemental Benefits"
      : null,
    requiresAdditionalInformation ? "Additional Information" : null,
    "Final Review",
  ].filter((title) => title !== null);
  SUPPLEMENTAL_LOCS.every((loc) => {
    supplemental.has(loc) || !lineOfCoverages.has(loc);
  });
  return (
    <Stack gap={20} style={{ width: "100%" }}>
      <Title size="h1">Enrollment: {stepTitles[step]}</Title>
      <Title size="h3">Select a plan or opt to decline coverage</Title>
      <Text>
        {employerName} is proud to offer stellar health benefits to prioritize
        your health and wellness.
      </Text>
      <VoluntarySupplementalBenefitCardFormProvider form={voluntaryForm}>
        <Stepper
          active={step}
          iconSize={32}
          onStepClick={(step) => setStep(step)}
          allowNextStepsSelect={false}
          styles={{
            steps: {
              overflowX: "auto",
            },
          }}
          wrap={false}
        >
          <Stepper.Step
            label={"Member Details"}
            description={showDescriptions && "Update info"}
          >
            <ConfirmMemberInfoFormProvider form={confirmMemberInfoForm}>
              <ConfirmDetails />
            </ConfirmMemberInfoFormProvider>
            <StepperControls
              onNext={onConfirmDetailsNext}
              nextEnabled={confirmMemberInfoForm.isValid()}
            />
          </Stepper.Step>

          {lineOfCoverages.has("medical") && (
            <Stepper.Step
              label={"Medical"}
              description={showDescriptions && "Select plan"}
            >
              <CoverageStep lineOfCoverage="medical" />
              <StepperControls
                onPrevious={onPrevious}
                onNext={onNext}
                nextEnabled={medical.quote !== undefined}
              />
            </Stepper.Step>
          )}

          {lineOfCoverages.has("dental") && (
            <Stepper.Step
              label={"Dental"}
              description={showDescriptions && "Select plan"}
            >
              <CoverageStep lineOfCoverage="dental" />
              <StepperControls
                onPrevious={onPrevious}
                onNext={onNext}
                nextEnabled={dental.quote !== undefined}
              />
            </Stepper.Step>
          )}
          {lineOfCoverages.has("vision") && (
            <Stepper.Step
              label={"Vision"}
              description={showDescriptions && "Select plan"}
            >
              <CoverageStep lineOfCoverage="vision" />
              <StepperControls
                onPrevious={onPrevious}
                onNext={onNext}
                nextEnabled={vision.quote !== undefined}
              />
            </Stepper.Step>
          )}
          {SUPPLEMENTAL_LOCS.some((loc) => lineOfCoverages.has(loc)) && (
            <Stepper.Step
              label={"Supplemental"}
              description={showDescriptions && "Optional"}
            >
              <SupplementalStep />
              <StepperControls
                onPrevious={onPrevious}
                onNext={onNext}
                nextEnabled={
                  SUPPLEMENTAL_LOCS.every(
                    (loc) => supplemental.has(loc) || !lineOfCoverages.has(loc),
                  ) && !voluntaryForm.isDirty()
                }
              />
            </Stepper.Step>
          )}
          {showTaxAdvantagedStep && (
            <Stepper.Step
              label={"Tax Advantaged Accounts"}
              description={showDescriptions && "Optional"}
            >
              <TaxAdvantagedStep />
              <StepperControls
                onPrevious={onPrevious}
                onNext={onNext}
                nextEnabled={TAX_ADVANTAGED_LOCS.every(
                  (loc) =>
                    (taxAdvantaged.has(loc) &&
                      taxAdvantaged.get(loc) !== null) ||
                    !lineOfCoverages.has(loc),
                )}
              />
            </Stepper.Step>
          )}
          {requiresAdditionalInformation && (
            <Stepper.Step
              label={"Additional Information"}
              description={showDescriptions && "Provide details"}
            >
              <AdditionalInfo form={additionalInfoForm} />
              <StepperControls
                onPrevious={onPrevious}
                onNext={onNext}
                nextEnabled={additionalInfoForm.isValid()}
              />
            </Stepper.Step>
          )}
          <Stepper.Step
            label={"Final Review"}
            description={showDescriptions && "Submit selection"}
          >
            <EmployeeEnrollFinalReview />
            <StepperControls
              onPrevious={onPrevious}
              onNext={onSubmit}
              nextText="Submit and continue"
              loading={submissionMutation.isPending}
            />
          </Stepper.Step>
          <Stepper.Completed>
            Completed, click back button to get to previous step
          </Stepper.Completed>
        </Stepper>
      </VoluntarySupplementalBenefitCardFormProvider>
    </Stack>
  );
};

interface StepperControlsProps {
  onNext: () => void;
  onPrevious?: () => void;
  nextText?: string;
  backText?: string;
  nextEnabled?: boolean;
  loading?: boolean;
}

const StepperControls = ({
  onNext,
  onPrevious,
  nextText = "Save and Continue",
  backText = "Back",
  nextEnabled = true,
  loading = false,
}: StepperControlsProps) => {
  return (
    <Group justify="flex-end" pt="35px">
      {onPrevious && (
        <Button onClick={onPrevious} variant="outline">
          {backText}
        </Button>
      )}
      <Button onClick={onNext} disabled={!nextEnabled} loading={loading}>
        {nextText}
      </Button>
    </Group>
  );
};

const generatePlanQuoteFromEnrollmentPlan = async (
  enrollmentPlan: EnrollmentPlan | undefined,
  enrollmentId: string,
  fetchEligiblePlans,
) => {
  if (!enrollmentPlan) {
    return null;
  }
  const planQuotes = await fetchEligiblePlans([
    {
      body: { dependents: getEnrollmentPlanDependentIds(enrollmentPlan) },
      enrollmentId: enrollmentId,
    },
  ]);
  enrollmentPlan.plan = enrollmentPlan.plan as Plan;
  return planQuotes.find(
    (planQuote) => planQuote.plan.id == (enrollmentPlan.plan as Plan).id,
  );
};
const getEnrollmentPlanDependentIds = (enrollmentPlan?: EnrollmentPlan) => {
  if (!enrollmentPlan) {
    return [];
  }

  return (enrollmentPlan.enrolled_dependents as EnrollmentPlanDependent[]).map(
    (enrolledDependent) => (enrolledDependent.dependent as Dependent).id,
  );
};

const makeCoverageSubmission = (
  lineOfCoverage: LineOfCoverage,
  planQuote: PlanQuote,
  enrolledDependentIds: string[],
  coverageWaivers: Map<string, z.infer<typeof CoverageWaiverSchema>>,
  primaryCareProviderMap: Map<
    string,
    z.infer<typeof PrimaryCareSelectionSchema>
  >,
  memberId: string,
) => {
  /**
   * Map enrollment selections to API format
   */
  const enrolledDependents = planQuote?.plan
    ? enrolledDependentIds.map((dependentId) => {
        const pcpDetails = primaryCareProviderMap.get(
          JSON.stringify([planQuote?.plan?.id, dependentId]),
        );

        return {
          dependent: dependentId,
          assign_primary_care_provider:
            pcpDetails?.assigned_by_carrier &&
            pcpDetails?.assigned_by_carrier === "auto",
          ...(pcpDetails?.assigned_by_carrier === "manual"
            ? {
                primaryCareProvider: {
                  fullName: pcpDetails.primary_care_provider_name,
                  providerIdNumber: pcpDetails.primary_care_provider_id,
                  isExistingPatient: pcpDetails.is_existing_patient === true,
                },
              }
            : {}),
        };
      })
    : [];

  const waivedDependents = [];
  const waiver = coverageWaivers.get(lineOfCoverage);
  if (planQuote && waiver?.reason) {
    waiver?.dependents &&
      waiver.dependents.forEach((dependent) =>
        waivedDependents.push({ dependent, waiveReason: waiver.reason }),
      );
  }
  const memberPCPDetails = primaryCareProviderMap.get(
    JSON.stringify([planQuote?.plan?.id, memberId]),
  );

  return {
    lineOfCoverage: lineOfCoverage,
    plan: planQuote?.plan?.id || null,
    enrolledDependents,
    waivedDependents,
    assign_primary_care_provider:
      memberPCPDetails?.assigned_by_carrier &&
      memberPCPDetails?.assigned_by_carrier === "auto",
    ...(memberPCPDetails?.assigned_by_carrier === "manual"
      ? {
          primaryCareProvider: {
            fullName: memberPCPDetails.primary_care_provider_name,
            providerIdNumber: memberPCPDetails.primary_care_provider_id,
            isExistingPatient: memberPCPDetails.is_existing_patient === true,
          },
        }
      : {}),
    waiveReason: waiver?.member && waiver?.reason,
  };
};

const makeVolumeCoverageSubmission = (
  lineOfCoverage: LineOfCoverage,
  planQuote: PlanQuote,
  volumeElections: VoluntarySupplementalBenefitElection[],
) => {
  /**
   * Map enrollment selections to API format for volume based plans
   */
  let memberElection: VoluntarySupplementalBenefitElection;
  const enrolledDependents = [];
  volumeElections.forEach((election) => {
    if (!election.memberRelationship) {
      memberElection = election;
      return;
    }

    Number(election.volume) > 0 &&
      enrolledDependents.push({
        dependent: election.entityId,
        volume: election.volume,
      });
  });
  if (Number(memberElection.volume) <= 0) return;

  return {
    lineOfCoverage: lineOfCoverage,
    plan: planQuote?.plan?.id || null,
    volume: memberElection.volume,
    enrolledDependents,
    waivedDependents: [],
  };
};

const makeTaxAdvantagedAccountSubmission = (
  lineOfCoverage: LineOfCoverage,
  selectedPlan: PlanQuoteWithDependents,
) => {
  if (selectedPlan.quote == null) {
    return;
  }
  return {
    lineOfCoverage: lineOfCoverage,
    plan: selectedPlan?.quote?.plan?.id || null,
    enrolledDependents: [],
    waivedDependents: [],
    taxAdvantagedAccountMemberContribution:
      selectedPlan?.taxAdvantagedAccountContribution ?? 0,
  };
};
