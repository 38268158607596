import { useApiClient } from "@/helpers/hooks";
import { Member, PayRate, WritablePayRate } from "@/types/api";
import {
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

export const useGetMember = (
  memberId: string,
  options?: Omit<UseQueryOptions<Member>, "queryKey">,
): UseQueryResult<Member> => {
  const client = useApiClient();

  return useQuery({
    ...options,
    queryKey: ["members", memberId],
    queryFn: () =>
      client.getMember(memberId, {
        expand: [
          "active_enrollment.enrolled_plans.plan.group.carrier",
          "active_enrollment.enrolled_plans.enrolled_dependents.dependent",
          "enrollments.enrolled_plans.plan.group.carrier",
          "enrollments.enrolled_plans.enrolled_dependents.dependent",
          "employer",
        ],
      }),
  });
};

export const usePayRates = (member?: string) => {
  const client = useApiClient();

  return useQuery({
    queryKey: ["pay_rates", member],
    queryFn: () =>
      client.listPayRates({ member, ordering: ["-effective_start"] }),
  });
};

export const usePayRate = (
  payRate?: string,
  options?: Omit<UseQueryOptions<PayRate>, "queryKey">,
) => {
  const client = useApiClient();

  return useQuery({
    queryKey: ["pay_rate", payRate],
    queryFn: () => client.getPayRate(payRate!),
    enabled: !!payRate,
    ...options,
  });
};

export const useDeletePayRateMutation = () => {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ payRate }: { payRate: string; member: string }) =>
      client.deletePayRate(payRate),
    onSuccess(_, { member }) {
      queryClient.invalidateQueries({ queryKey: ["pay_rates", member] });
    },
  });
};

export const useUpdatePayRateMutation = () => {
  const queryClient = useQueryClient();
  const client = useApiClient();
  return useMutation({
    mutationFn: ({
      payRate,
      data,
    }: {
      payRate: string;
      data: WritablePayRate;
    }) => client.updatePayRate(payRate, data),
    onSuccess({ member, id }) {
      queryClient.invalidateQueries({ queryKey: ["pay_rates", member] });
      queryClient.invalidateQueries({ queryKey: ["pay_rate", id] });
    },
  });
};
