import { PayRatePeriods } from "@/types/api";
import { z } from "zod";

export const PayRateSchema = z.object({
  amount: z.number().min(0),
  period: z.enum(PayRatePeriods),
  effective_start: z.date(),
});

export type CreatePayRateType = z.infer<typeof PayRateSchema>;
