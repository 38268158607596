import { CarrierImage } from "@/components/CarrierImage";
import { PLAN_TYPE, PayFrequencyOptions } from "@/constants";
import { snakeCaseToTitleCase } from "@/helpers/string";
import {
  Group as GroupType,
  LineOfCoverage,
  Plan,
  PlanQuote,
} from "@/types/api";
import {
  Alert,
  Anchor,
  Badge,
  Center,
  Divider,
  Flex,
  Group,
  Paper,
  SimpleGrid,
  Skeleton,
  Space,
  Stack,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useMemo } from "react";
import { IconHelpCircle } from "@tabler/icons-react";
import { SelectButton } from "../SelectButton";
import { memberDependentsAtom, memberInfoAtom } from "../../API";
import { useAtomValue } from "jotai";
import { PlanQuoteWithDependents } from "../../EnrollProvider";

interface Props {
  planQuoteWithDependents: PlanQuoteWithDependents;
  selected: PlanQuote;
  onSelect?: any;
  canBeSelected?: boolean;
  loading?: boolean;
}

export const PlanCard = ({
  planQuoteWithDependents: { quote, dependents: selectedDependents },
  selected: selectedPlanQuote,
  onSelect,
  canBeSelected,
  loading = false,
}: Props) => {
  const plan = quote?.plan as Plan;
  const selectedPlan = selectedPlanQuote?.plan as Plan;
  const group = plan.group as GroupType;
  const memberInfo = useAtomValue(memberInfoAtom[0]);
  const dependents = useAtomValue(memberDependentsAtom);
  const isSelected = useMemo(
    () => selectedPlan?.id === plan?.id,
    [selectedPlan, plan],
  );
  const theme = useMantineTheme();

  const planType = plan.plan_type;
  const badgeColor = PLAN_TYPE[planType]?.badgeColor;
  const badge = badgeColor ? (
    <Tooltip
      label={PLAN_TYPE[planType].tooltip}
      position="right-start"
      withArrow
    >
      <Badge
        size="lg"
        color={badgeColor}
        variant="light"
        bg={PLAN_TYPE[planType].badgeBackground}
      >
        {PLAN_TYPE[planType].label}
      </Badge>
    </Tooltip>
  ) : (
    <></>
  );

  const hsaBadge = plan.hsa_eligible ? (
    <Tooltip
      label="This plan is eligible for a Health Savings Account (HSA)"
      position="right-start"
      withArrow
    >
      <Badge size="lg" variant="light">
        HSA-Eligible
      </Badge>
    </Tooltip>
  ) : (
    <></>
  );

  const planBundleMap = plan.bundle?.plans.reduce((map, plan) => {
    const existingPlans = map.get(plan.line_of_coverage) || [];
    existingPlans.push(plan);
    map.set(plan.line_of_coverage, existingPlans);

    return map;
  }, new Map<LineOfCoverage, Plan[]>());

  return (
    <Paper
      p="lg"
      withBorder
      shadow="xs"
      style={
        isSelected &&
        !loading && {
          border: `3px solid ${
            theme.colors[theme.primaryColor][5] || theme.primaryColor
          }`,
        }
      }
    >
      <Flex gap={12} direction="column" style={{ width: "100%" }}>
        <Group gap={8} justify={"space-between"} style={{ width: "100%" }}>
          <Flex gap={24}>
            {loading ? (
              <Skeleton w={100} h={100} />
            ) : (
              <CarrierImage carrierId={group?.carrier.toString()} />
            )}

            <Flex direction="column" gap={12} justify={"center"}>
              {loading ? (
                <Skeleton w={60}>
                  <Badge size="lg" />
                </Skeleton>
              ) : (
                <Flex gap={12}>
                  {badge}
                  {hsaBadge}
                </Flex>
              )}
              {loading ? (
                <>
                  <Skeleton w={128}>
                    <Text>&nbsp;</Text>
                  </Skeleton>
                  <Skeleton w={196}>
                    <Text>&nbsp;</Text>
                  </Skeleton>
                </>
              ) : (
                <Text>{plan.plan_name}</Text>
              )}
              {plan.bundle && (
                <Group>
                  <Group gap={8}>
                    <Title order={5}>Plan bundle {plan.bundle.name}</Title>

                    <Tooltip
                      position="top"
                      withArrow
                      label="This plan is part of a bundle. Choosing it means you'll only be able to select plans in subsequent steps that are part of this bundle."
                    >
                      <IconHelpCircle
                        stroke={1.5}
                        size={24}
                        color={theme.colors[theme.primaryColor][5]}
                      />
                    </Tooltip>
                  </Group>
                  {Array.from(planBundleMap).map(
                    ([lineOfCoverage, bundledPlans]) => (
                      <Tooltip
                        label={
                          <Stack>
                            {bundledPlans.map(({ plan_name }) => (
                              <>
                                {plan_name}
                                <br />
                              </>
                            ))}
                          </Stack>
                        }
                        position="bottom"
                        withArrow
                      >
                        <Badge variant="light" size="lg" color="black">
                          {lineOfCoverage}
                        </Badge>
                      </Tooltip>
                    ),
                  )}
                </Group>
              )}
            </Flex>
          </Flex>
          {loading ? (
            <Skeleton w={104} h={60} />
          ) : (
            <Title size="h2">
              <Group gap="xs">
                ${quote.per_pay_period_member_contribution}
                <Tooltip
                  label={
                    <Stack gap="xs">
                      <Group gap="xs">
                        <Text size="sm" fw="bold">
                          Pay Period:
                        </Text>

                        <Text size="sm">
                          {
                            PayFrequencyOptions.find(
                              ({ value }) => value === memberInfo.pay_frequency,
                            )?.label
                          }
                        </Text>
                      </Group>

                      <Group gap="xs">
                        <Text size="sm" fw="bold">
                          Covered:
                        </Text>

                        <Text size="sm">
                          {[
                            "You",
                            ...dependents
                              .filter(({ id }) =>
                                selectedDependents.includes(id),
                              )
                              .map(
                                ({ first_name, last_name }) =>
                                  `${first_name} ${last_name}`,
                              ),
                          ].join(", ")}
                        </Text>
                      </Group>
                    </Stack>
                  }
                  withArrow
                >
                  <IconHelpCircle
                    stroke={1.5}
                    size={25}
                    color={theme.colors[theme.primaryColor][5]}
                  />
                </Tooltip>
              </Group>
              <Text>Per pay period</Text>
            </Title>
          )}
        </Group>

        {loading ? (
          <Skeleton
            w="100%"
            h={{
              base: 380,
              xs: 198,
              lg: 108,
            }}
          />
        ) : (
          <Alert>
            <SimpleGrid cols={{ xs: 2, md: 4, lg: 4, xl: 4 }}>
              {plan.plan_details &&
                Object.entries(plan.plan_details).map(
                  ([key, value], index, array) => (
                    <div>
                      {typeof value === "string" ? (
                        <Text fw={600} key={key}>
                          <Center>{snakeCaseToTitleCase(key)}</Center>
                          <br />
                          <Center>
                            <Text>{value}</Text>
                          </Center>
                        </Text>
                      ) : (
                        <Text fw={600} key={key} pl="md">
                          <Center>
                            {value.label}
                            <Space w="4" />
                            {value.tooltip && (
                              <Tooltip
                                label={value.tooltip}
                                position="top"
                                withinPortal
                                withArrow
                              >
                                <IconHelpCircle
                                  stroke={1.5}
                                  size={17.5}
                                  color={theme.colors[theme.primaryColor][5]}
                                />
                              </Tooltip>
                            )}
                          </Center>
                          <Space h="xs" />
                          <Center>
                            <Stack gap={2} align="center">
                              {value.info_lines.map((line) => (
                                <Text>{line}</Text>
                              ))}
                            </Stack>
                          </Center>
                        </Text>
                      )}
                      {index !== array.length - 1 && (
                        <Divider orientation="vertical" />
                      )}
                    </div>
                  ),
                )}
            </SimpleGrid>
          </Alert>
        )}
        <Group justify="space-between">
          {plan.plan_summary_url ? (
            <Anchor href={plan.plan_summary_url} target="_blank" size="lg">
              <b>View full plan details</b>
            </Anchor>
          ) : (
            <div style={{ visibility: "hidden" }} />
          )}
          {onSelect !== undefined &&
            (loading ? (
              <Skeleton w={120}>
                <SelectButton />
              </Skeleton>
            ) : (
              <SelectButton
                selected={isSelected}
                onClick={() => onSelect(quote)}
                canBeSelected={canBeSelected}
              />
            ))}
        </Group>
      </Flex>
    </Paper>
  );
};
