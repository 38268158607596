import { LineOfCoverage, PlanQuote } from "@/types/api";
import { useHash } from "@mantine/hooks";
import { useAtomValue } from "jotai";
import { createContext, useCallback, useContext, useReducer } from "react";
import { memberInfoAtom } from "./API";

export interface PlanQuoteWithDependents {
  quote?: PlanQuote | null;
  dependents: string[];
  taxAdvantagedAccountContribution?: number | null;
}

type SupplementalPlansMap = Map<LineOfCoverage, PlanQuoteWithDependents>;
type TaxAdvantagedPlansMap = Map<LineOfCoverage, PlanQuoteWithDependents>;

export type EnrollState = {
  medical: PlanQuoteWithDependents;
  vision: PlanQuoteWithDependents;
  dental: PlanQuoteWithDependents;
  supplemental: SupplementalPlansMap;
  taxAdvantaged: TaxAdvantagedPlansMap;
  acaDigitalFormsEnabled: boolean;
};

type SetMedicalAction = {
  type: "set-medical";
  payload?: Partial<PlanQuoteWithDependents>;
};
type SetDentalAction = {
  type: "set-dental";
  payload?: Partial<PlanQuoteWithDependents>;
};
type SetVisionAction = {
  type: "set-vision";
  payload?: Partial<PlanQuoteWithDependents>;
};
type SetSupplementalAction = {
  type: "set-supplemental";
  payload: SupplementalPlansMap;
};
type SetTaxAdvantagedAction = {
  type: "set-tax-advantaged";
  payload: TaxAdvantagedPlansMap;
};
type SetAcaDigitalFormsEnabled = {
  type: "set-aca-digital-forms-enabled";
  payload: boolean;
};
type ResetAction = {
  type: "reset";
  payload?: void;
};

type Action =
  | SetMedicalAction
  | SetDentalAction
  | SetVisionAction
  | SetSupplementalAction
  | SetTaxAdvantagedAction
  | SetAcaDigitalFormsEnabled
  | ResetAction;
type Dispatch = (action: Action) => void;

const EnrollContext = createContext<
  | {
      state: EnrollState;
      dispatch: Dispatch;
      step: number;
      setStep: (newStep: number) => void;
    }
  | undefined
>(undefined);

const initialState: EnrollState = {
  medical: { dependents: [] },
  dental: { dependents: [] },
  vision: { dependents: [] },
  supplemental: new Map(),
  taxAdvantaged: new Map(),
  acaDigitalFormsEnabled: false,
};

const enrollReducer = (
  state: EnrollState,
  { type, payload }: Action,
): EnrollState => {
  switch (type) {
    case "set-medical":
      return {
        ...state,
        medical: payload === null ? null : { ...state.medical, ...payload },
      };
    case "set-dental":
      return {
        ...state,
        dental: payload === null ? null : { ...state.dental, ...payload },
      };
    case "set-vision":
      return {
        ...state,
        vision: payload === null ? null : { ...state.vision, ...payload },
      };
    case "set-supplemental":
      return { ...state, supplemental: payload };
    case "set-tax-advantaged":
      return { ...state, taxAdvantaged: payload };
    case "set-aca-digital-forms-enabled":
      return { ...state, acaDigitalFormsEnabled: payload };
    case "reset":
      return { ...initialState };
  }
};

export const EnrollProvider = ({ children }: { children: React.ReactNode }) => {
  const member = useAtomValue(memberInfoAtom[0]);
  const [state, dispatch] = useReducer(enrollReducer, {
    ...initialState,
    acaDigitalFormsEnabled: !!member.aca_digital_forms_enabled_at,
  });
  const [stepNumberString, setStepNumberString] = useHash();
  const step = Number(stepNumberString.replace("#", ""));

  const setStep = useCallback(
    (newStep: number | null) => {
      setStepNumberString(newStep === null ? "" : newStep.toString());
    },
    [setStepNumberString],
  );

  const value = { state, dispatch, step, setStep };

  return (
    <EnrollContext.Provider value={value}>{children}</EnrollContext.Provider>
  );
};

export const useEnrollContext = () => {
  const data = useContext(EnrollContext);

  if (typeof data.dispatch !== "function") {
    throw new Error("useEnrollContext must be used from within EnrollProvider");
  }

  return data;
};
