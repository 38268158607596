import { PayPeriodMapping } from "@/constants";
import { useApiClient } from "@/helpers/hooks";
import { UsCurrencyFormatter, UsMediumDateFormatter } from "@/helpers/string";
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerProps,
  Flex,
  Group,
  Stack,
  Text,
  Timeline,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import {
  IconArrowLeft,
  IconCircle,
  IconCircleCheck,
  IconClockHour7,
  IconPlus,
} from "@tabler/icons-react";
import { useState } from "react";
import { useGetPartnerConfig } from "../ViewEmployees/hooks";
import { PayRateDialog } from "./PayRateDialog";
import classes from "./PayRatesDrawer.module.css";
import { useDeletePayRateMutation, useGetMember, usePayRates } from "./hooks";
import { PayRateType, getPayRateTypes } from "./utils";

interface Props extends DrawerProps {
  member: string;
}

const PayRateTypeIcon = {
  [PayRateType.Future]: <IconClockHour7 size={24} stroke={1.5} />,
  [PayRateType.Current]: <IconCircleCheck size={24} stroke={1.5} />,
  [PayRateType.Past]: <IconCircle size={24} stroke={1.5} />,
};

export const PayRatesDrawer = ({ member, onClose, ...rest }: Props) => {
  const { data: memberData } = useGetMember(member);
  const { data: payRatesData } = usePayRates(member);
  const [selectedPayRate, setSelectedPayRate] = useState<string>();
  const deletePayRateMutation = useDeletePayRateMutation();
  const client = useApiClient();
  const partnerConfig = useGetPartnerConfig({ client });

  const [detailsOpened, { open: openDetails, close: closeDetails }] =
    useDisclosure(false);

  const handleClickEdit = (payRate?: string) => {
    setSelectedPayRate(payRate);
    openDetails();
  };

  const handleCloseDetails = () => {
    setSelectedPayRate(undefined);
    closeDetails();
  };

  const handleClickDelete = (payRate: string) => {
    modals.openConfirmModal({
      title: "Are you sure that you want to delete the pay rate?",
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: {
        variant: "danger",
        size: "sm",
        vars: () => ({
          root: {
            "--button-bg": "red",
          },
        }),
      },
      cancelProps: { variant: "outline", size: "sm" },
      onConfirm: () => {
        deletePayRateMutation.mutate(
          {
            member,
            payRate,
          },
          {
            onSuccess: () => {
              notifications.show({
                title: "Pay rate deleted",
                message: `Pay rate has successfully been deleted.`,
              });
            },
          },
        );
      },
    });
  };

  const payRatesWithTypes = getPayRateTypes(payRatesData?.results ?? []);
  const handleClose = () => {
    closeDetails();
    onClose();
  };

  return (
    <>
      <Drawer
        position="right"
        {...rest}
        withCloseButton={false}
        padding={0}
        onClose={handleClose}
      >
        <Stack gap={0} h="calc(100vh)" justify="space-between">
          <Stack gap={0}>
            <Group px="md" py="xl">
              <ActionIcon variant="transparent" color="#1A1B25">
                <IconArrowLeft onClick={onClose} />
              </ActionIcon>
              <Title
                order={4}
                c="#1A1B25"
              >{`${memberData?.first_name} ${memberData?.last_name} - Pay History`}</Title>
            </Group>
            <Divider />
            <Box px="md" pt="xl" pb="xl">
              <Timeline
                px="md"
                active={payRatesData?.results.length}
                bulletSize={24}
                lineWidth={2}
                classNames={classes}
                vars={() => ({
                  root: {
                    "--tl-icon-color": "var(--tl-color)",
                  },
                })}
              >
                {payRatesWithTypes.map(
                  ({ amount, period, effective_start, id, type }) => {
                    return (
                      <Timeline.Item
                        bullet={PayRateTypeIcon[type]}
                        key={id}
                        data-type={type}
                      >
                        <Group justify="space-between">
                          <Stack gap={0}>
                            <Text
                              c={
                                type === PayRateType.Current
                                  ? "primary"
                                  : "#212927"
                              }
                              fw={
                                type === PayRateType.Current ? "bold" : "normal"
                              }
                            >
                              {`${UsCurrencyFormatter.format(amount)} ${
                                PayPeriodMapping[period]
                              }`}
                            </Text>
                            <Text size="sm" c="#505755">
                              {UsMediumDateFormatter.format(
                                new Date(effective_start),
                              )}
                            </Text>
                          </Stack>
                          {partnerConfig?.data?.flags
                            ?.enable_employee_management && (
                            <Group gap="xs">
                              <Button
                                disabled={deletePayRateMutation.isPending}
                                size="compact-sm"
                                variant="subtle"
                                onClick={() => handleClickEdit(id)}
                              >
                                Edit
                              </Button>
                              {payRatesWithTypes.length > 1 && (
                                <Button
                                  disabled={deletePayRateMutation.isPending}
                                  c="red"
                                  size="compact-sm"
                                  variant="subtle"
                                  onClick={() => handleClickDelete(id)}
                                >
                                  Delete
                                </Button>
                              )}
                            </Group>
                          )}
                        </Group>
                      </Timeline.Item>
                    );
                  },
                )}
              </Timeline>
            </Box>
          </Stack>
          {partnerConfig?.data?.flags?.enable_employee_management && (
            <>
              <Box>
                <Divider m={0} />
                <Flex justify="flex-end" py="24" px="38">
                  <Button
                    onClick={() => handleClickEdit(undefined)}
                    leftSection={<IconPlus size={16} />}
                    disabled={deletePayRateMutation.isPending}
                  >
                    Add Rate
                  </Button>
                </Flex>
              </Box>
            </>
          )}
        </Stack>
      </Drawer>
      <PayRateDialog
        opened={detailsOpened}
        member={member}
        onClose={handleCloseDetails}
        payRate={selectedPayRate}
      />
    </>
  );
};
