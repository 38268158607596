import { CUSTOM_VOLUME_LOCS } from "@/constants";
import { MemberRelationship } from "@/types/api";
import { createFormContext } from "@mantine/form";
import { useAtomValue } from "jotai";
import { memberDependentsAtom, memberInfoAtom } from "../../API";

export type VoluntarySupplementalBenefitElection = {
  entityId: string;
  volume: string;
  memberRelationship?: MemberRelationship;
};

type CustomVolumeLOCs = (typeof CUSTOM_VOLUME_LOCS)[number];

type VoluntarySupplementalBenefitCardValues = {
  [K in CustomVolumeLOCs]: VoluntarySupplementalBenefitElection[];
};

export const [
  VoluntarySupplementalBenefitCardFormProvider,
  useVoluntarySupplementalBenefitCardFormContext,
  useVoluntarySupplementalBenefitCardForm,
] = createFormContext<VoluntarySupplementalBenefitCardValues>();

export const useVoluntarySupplementalBenefitCardFormInitialValues =
  (): VoluntarySupplementalBenefitCardValues => {
    const dependents = useAtomValue(memberDependentsAtom);
    const memberInfo = useAtomValue(memberInfoAtom[0]);
    const initialElections = [
      {
        entityId: memberInfo.id,
        volume: "0",
      },
      ...dependents.map(({ id, member_relationship }) => ({
        entityId: id,
        memberRelationship: member_relationship,
        volume: "0",
      })),
    ];

    return {
      voluntary_life: initialElections,
      voluntary_critical_illness: initialElections,
      voluntary_short_term_disability: initialElections,
    };
  };
